html * {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #f0f0f0;
}

#root {
  width: 100%;
  height: 100%;
}

/* Buttons css */

.ant-btn-primary {
  background-color: #343a40;
  border-color: #343a40;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #7c838a;
  background-color: #7c838a;
}

.ant-btn-danger {
  background-color: #e40b14;
}

.ant-btn-default {
  background-color: #e6007d;
  border-color: #e6007d;
  color: #ffffff;
}

.ant-btn-default:hover, .ant-btn-default:focus {
  border-color: #e295be;
  background-color: #e295be;
  color: #ffffff;
}

.ant-pagination-item-active {
  border-color: #e6007d !important;
}

.ant-pagination-item-active a {
  color: #e6007d !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}
.ant-layout-sider-collapsed h1 > span{
  display: none;
}

/* Table css */

.table {
  width: 100%;
  border-collapse: collapse;
}

tbody, td, tfoot, th, thead, tr {
  border-collapse: collapse;
  border-color: #efefef;
  border-width: 0px;
  border-style: solid;
}

.table td, .table th {
  border-width: 1px;
  padding: 5px 8px;
}

.table td {
  word-break: break-all;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.bg-primary {
  background-color: #2077B4;
}

.bg-warning {
  background-color: #FF7F0E;
}

.bg-danger {
  background-color: #D62728;
}

.bg-success {
  background-color: #2DA02C;
}

.mb-0 {
  margin-bottom: 0;
}

.overflow-auto {
  overflow: auto;
}

.ant-layout-sider-collapsed h1[class^='brandName_'], .ant-layout-sider-collapsed h1[class*='brandName_'] {
  font-size: 16px !important;
  transition: font-size 0.2s;
}

.header-overflow .ant-card-header {
  overflow: auto;
}

.ant-card-body {
  overflow: auto;
}